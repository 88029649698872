import React, { useState, useEffect, useCallback, useRef } from "react";
import { useQuery, useQueryClient } from "@tanstack/react-query";
import axios from "axios";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import ReactPlayer from "react-player";
import PlaylistModal from "./PlaylistModal";
import {
  Button,
  CircularProgress,
  TextField,
  Select,
  MenuItem,
  Dialog,
  DialogActions,
  DialogTitle,
  DialogContent,
  Skeleton,
} from "@mui/material";
import { Form, Field } from "react-final-form";
import { Select as RffSelect } from "mui-rff";
import { Autocomplete, makeValidate } from "mui-rff";
import styles from "./VideoManager.module.scss";
import debounce from "lodash.debounce";
import TikTok from "react-tiktok";
import YouTube, { YouTubeProps } from "react-youtube";
import { toast } from "react-toastify";
import { Delete } from "@mui/icons-material";
import IconButton from "@mui/material/IconButton";
import { confirmAlert } from "react-confirm-alert";
import ClearIcon from "@mui/icons-material/Clear";
import { Clear } from "@mui/icons-material";
import { useNavigate } from "react-router-dom";
import {
  FormControl,
  FormControlLabel,
  FormLabel,
  Radio,
  RadioGroup,
  Checkbox,
} from "@mui/material";
import { Chip } from "@mui/material";
import PublicIcon from "@mui/icons-material/Public";
import Person2Icon from "@mui/icons-material/Person2";
import ArchiveIcon from "@mui/icons-material/Archive";
import DeleteIcon from "@mui/icons-material/Delete";
import BusinessIcon from "@mui/icons-material/Business";
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import Groups2Icon from '@mui/icons-material/Groups2';
import Lottie from "lottie-react";
import videoLoadingAnimation from "../animation/Animation - 1741783692232.json"; // Update this path as needed


export default function VideoManager() {
  const [categories, setCategories] = useState([]);
  const [currentCategory, setCurrentCategory] = useState(null);
  const [currentVideo, setCurrentVideo] = useState(null);
  const [loading, setLoading] = useState(true);
  const [animationData, setAnimationData] = useState(null);
  const [searchLoading, setSearchLoading] = useState(false);
  const [searchParam, setSearchParam] = useState("");
  const [searchResults, setSearchResults] = useState([]);
  const [treatments, setTreatments] = useState([]);
  const [selectedTreatment, setSelectedTreatment] = useState("");
  const [userid, setUserId] = useState("");
  const [practiceId, setPracticeId] = useState("");
  const [newPlaylistName, setNewPlaylistName] = useState("");
  const [openModal, setOpenModal] = useState(false);
  const [openModalPlaylist, setOpenModalPlaylist] = useState(false);
  const queryClient = useQueryClient();
  const startTimeRef = useRef(null);
  const playbackTimeRef = useRef(0);
  const [playlistData, setPlaylistData] = useState(null);
  const [selectedCategoryId, setSelectedCategoryId] = useState(null);
  const [selectedCategoryName, setSelectedCategoryName] = useState("");

  const [lastPlayedSeconds, setLastPlayedSeconds] = useState(null);
  const [playedBeforePause, setPlayedBeforePause] = useState(null);
  const navigate = useNavigate();
  const [level, setLevel] = useState("");
  const [isArchived, setIsArchived] = useState(false);
  const [itemId, setIteamId] = useState("");
  
  const treatment_Categories = useQuery(["categories"], async () => {
    const { data } = await axios.post(`/treatment_category`);
    return data;
  });

  const { data: playlistCategories } = useQuery(
    ["playlistCategories"],
    async () => {
      const { data } = await axios.post(`/treatment_category`);
      return data;
    }
  );

  const [treatmentPlans, setTreatmentPlans] = useState([]);

  useEffect(() => {
    const fetchTreatmentPlans = async () => {
      try {
        const { data } = await axios.get(`/treatment`);
        const plans = data.treatments.map((plan) => ({
          ...plan,
          label: plan.description,
          value: plan.id,
        }));
        setTreatmentPlans(plans);
      } catch (error) {
        console.error("Error fetching treatment plans:", error);
      }
    };
    fetchTreatmentPlans();
  }, []);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const treatmentResponse = await axios.get("/treatment_category");
        const treatmentOptions = treatmentResponse.data.map((plan) => ({
          label: plan.category_name,
          value: plan.id,
        }));
        setTreatments(treatmentOptions);
        const userResponse = await axios.get("/user");
        setUserId(userResponse.data[1].medicube_user_id);
        setPracticeId(userResponse.data[1].medicube_practice_id);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };
    handleData();
    fetchData();
  }, []);

  const [filter, setFilter] = useState<string>("all");
  const [selectedTreatmentIds, setSelectedTreatmentIds] = useState([]);
  const [selectedTreatmentIdsNew, setSelectedTreatmentIdsNew] = useState([]);


  useEffect(() => {
  if (currentCategory) {
    if (currentCategory.level === "global") {
      setSelectedTreatmentIds([]);
    } else if (currentCategory.treatment_ids) {
      setSelectedTreatmentIds(currentCategory.treatment_ids);
    }
  }
}, [currentCategory]);

const handleData = (category: number, search: string) => {
  setLoading(true);
    setLoading(true);
    axios.get(`/playlist/`, { params: { category, search } })
      .then((response) => {
        const {
          default_user_playlists = [],
          default_practice_playlists = [],
          global_playlists = [],
          non_default_playlists = [],
          treatment_categories = [],
        } = response.data;

        const allPlaylists = [
          ...default_user_playlists.map((item, index) => {
            if (index === 0) {
              setIteamId(item.id); 
            }
            return {
              ...item,
              type: "default_user_playlists",
            };
          }),
          ...default_practice_playlists.map((item) => ({
            ...item,
            type: "default_practice_playlists",
          })),
          ...global_playlists.map((item) => ({
            ...item,
            type: "global_playlists",
          })),
          ...non_default_playlists.map((item) => ({
            ...item,
            type: "non_default_playlists",
          })),
        ];
        

        
            const formattedData = allPlaylists.map((item) => {
              const videos = item.playist_items || [];
              const firstVideoTitle =
                videos.length > 0
                  ? videos[0].title || `Category ${item.id}`
                  : `Category ${item.id}`;

                  
              return {
                id: item.id,
                name: item.title || firstVideoTitle,
                type: item.type,
                level: item.level,
                treatment_category_id: item.treatment_category_id,
                treatment_ids: item.treatment_ids,
                is_default: item.is_default,
                videos: videos.map((video) => ({
                  title: video.title,
                  url: video.url,
                  thumbnail: video.thumbnail,
                  description: video.description,
                  platform: video.platform,
                  duration: video.duration,
                })),
              };
            });
            setCategories(formattedData);
          

        if (formattedData.length > 0) {
          setCurrentCategory(formattedData[0]);
          setSelectedCategoryId(formattedData[0].id);

          const selectedCategory = treatment_categories.find(
            (cat) => cat.id === formattedData[0].id
          );

          setSelectedCategoryName(
            selectedCategory ? selectedCategory.name : "Unknown Category"
          );

          if (formattedData[0].videos.length > 0) {
            setCurrentVideo(formattedData[0].videos[0]);
          } else {
            setCurrentVideo(null);
          }
        } else {
          setCurrentCategory(null);
          setCurrentVideo(null);
        }

        setLoading(false);
      })
      .catch((error) => {
        console.error("Error fetching the playlist data:", error);
        setLoading(false);
      });
  };

  //update uder and practice 
  const updatePlaylist = async (values) => {
    try {
      const payload = {
        title: values.playlistName,
        treatment_category_id: values.category_id,
        treatment_ids: selectedTreatmentIds,
        level: values.options,
      };
  
      await axios.put(`/playlist/${currentCategory.id}`, payload);
  
      try {
        const getResponse = await axios.get(`/playlist/${currentCategory.id}`);
        setLevel(getResponse.data.level);
        setIteamId(getResponse.data.id);
        setIsArchived(getResponse.data.is_archived);
        setSelectedCategoryName(getResponse.data.treatment_category_name);
      } catch (error) {
        console.error("Error fetching playlist:", error);
      }
  
      handleData();
  
      const abc = await axios.get(`/playlist/${itemId}`);
      const transformedData = {
        id: abc.data.id,
        name: abc.data.title || firstVideoTitle,
        type: abc.data.type || "default",
        level: abc.data.level,
        treatment_category_id: abc.data.treatment_category_id,
        treatment_ids: abc.data.treatment_ids || [],
        is_default: abc.data.is_default,
        videos: (abc.data.playist_items || []).map((video) => ({
          title: video.title,
          url: video.url,
          thumbnail: video.thumbnail,
          description: video.description,
          platform: video.platform,
          duration: video.duration,
        })),
      };
  
      onCategorySelect(transformedData);
      setSelectedCategoryId(transformedData.id);
      setCurrentCategory(transformedData);
  
      toast.success("Playlist edited successfully");
  
    } catch (error) {
      console.error("Error updating playlist:", error);
    }
  };
  


  const clonePlaylist = async (values) => {
    try {
      // Validate if treatment is selected and level is not empty
      if (!values.treatment_ids || values.treatment_ids.length === 0) {
        toast.error("Please select at least one treatment.");
        return; // Exit function early if validation fails
      }

      if (!values.options || values.options.trim() === "") {
        toast.error("Please select a level.");
        return; // Exit function early if validation fails
      }

      const payload = {
        title: values.playlistName,
        treatment_category_id: values.category_id,
        treatment_ids: values.treatment_ids
          ? values.treatment_ids.map((t) => t.value)
          : [],
        level: values.options,
      };

      const response = await axios.put(
        `/playlist/${currentCategory.id}/copy_global_playlist_to_private`,
        payload
      );
      setLevel(response.data.level); 
      const newPlaylist = {
        id: response.data.id,
        name: values.playlistName,
        videos: response.data.playist_items.map((video) => ({
          id: video.id,
          title: video.title,
          url: video.url,
          thumbnail: video.thumbnail || "",
          description: video.description,
          type: video.type,
        })),
        level: response.data.level,
        type: response.data.level === "practice" 
          ? "default_practice_playlists" 
          : "default_user_playlists",
        is_default: response.data.is_default,
        treatment_category_id: response.data.treatment_category_id,
        treatment_ids: response.data.treatment_ids || [],
      };
      setIteamId(newPlaylist.id)
    
      setCategories((prevCategories) => [newPlaylist, ...prevCategories]);
      setCurrentCategory(newPlaylist);
      setSelectedCategoryId(newPlaylist.id);
      setCurrentVideo(
        newPlaylist.videos.length > 0
          ? {
              ...newPlaylist.videos[0], 
              url: newPlaylist.videos[0].url, 
            }
          : null
      );

      toast.success("Playlist created successfully");

    } catch (error) {
      const errorMessage = error.response?.data?.detail || "An error occurred while cloning the playlist.";
      toast.error(errorMessage);
      console.error("Error cloning playlist:", error);
    }
};

  const handleSubmitPlaylistUpdate = (values) => {
    if (currentCategory.level === "global") {
      clonePlaylist(values);
    } else {
      updatePlaylist(values);
    }
  };


  const onCategorySelect = async (category) => {
    setSelectedCategoryId(category.id);
    try {
      const response = await axios.get(`/playlist/${category.id}`);
      setLevel(response.data.level);
      setIteamId(response.data.id);

      if (response.data.is_archived) {
        setIsArchived(true);
      }else{setIsArchived(false);}
      setSelectedCategoryName(response.data.treatment_category_name);
      
      setCurrentCategory(category);

      if (category.videos.length > 0) {
        setCurrentVideo(category.videos[0]);
      } else {
        setCurrentVideo(null);
      }

      logUserAction("Treatment Selected", "User clicked on a treatment", {
        treatment: category.name,
      });
    } catch (error) {
      console.error("Error fetching playlist:", error);
    }
  };



//playlist save 
const handleSubmitPlaylistSave = async (formValues) => {
  try {
    const { playlistName, category_id } = formValues;
    const categoryIdInt = parseInt(category_id, 10);

    const response = await axios.post("/playlist/", {
      title: playlistName,
      treatment_category_id: categoryIdInt,
      treatment_ids: selectedTreatmentIdsNew,
    });

    const newPlaylist = {
      id: response.data, // Assuming the API returns the playlist ID
      name: playlistName,
      treatment_category_id: categoryIdInt,
      videos: [],
      level: "user",
      type: "default_user_playlists",
    };

    // Fetch the newly created playlist to get additional details
    const getResponse = await axios.get(`/playlist/${newPlaylist.id}`);

    // Set the necessary state
    setIteamId(getResponse.data.id);
    setCategories((prevCategories) => [newPlaylist, ...prevCategories]);
    setCurrentCategory(newPlaylist);  // Set the newly created playlist as the current category
    setSelectedCategoryId(newPlaylist.id);  // Automatically select the newly created playlist
    setSelectedCategoryName(getResponse.data.treatment_category_name);
    setSelectedTreatmentIds(getResponse.data.treatment_ids);
    setCurrentVideo(null); // Reset the current video if needed
    
    // Toast success message
    toast.success("Playlist created successfully");
    handleModalClose(); // Close the modal after successful creation

  } catch (error) {
    toast.error("Error saving playlist.");
    console.error("Error saving playlist:", error);
  }
};





  const fetchPlaylistsByTreatment = (treatmentId) => {
    setLoading(true);
    axios
      .get(`/playlist/?treatment_category_id=${treatmentId}`)
      .then((response) => {
        const data = response.data.map((item) => {
          const videos = item.playist_items || [];
          const firstVideoTitle =
            videos.length > 0
              ? videos[0].title || `Category ${item.id}`
              : `Category ${item.id}`;
          return {
            name: item.title || firstVideoTitle,
            videos: videos.map((video) => ({
              title: video.title,
              url: video.url,
              thumbnail: video.thumbnail,
            })),
          };
        });
        setCategories(data);
        if (data.length > 0) {
          setCurrentCategory(data[0]);
          if (data[0].videos.length > 0) {
            setCurrentVideo(data[0].videos[0]);
          } else {
            setCurrentVideo(null);
          }
        } else {
          setCurrentCategory(null);
          setCurrentVideo(null);
        }
        setLoading(false);
      })
      .catch((error) => {
        console.error("Error fetching the playlist data:", error);
        setLoading(false);
      });
  };

  const handleSearch = useCallback(
    debounce(() => {
      if (searchParam.trim() === "") {
        handleData();
        return;
      }
  
      setSearchLoading(true);
      axios
        .get(`/playlist/?search=${searchParam}`)
        .then((response) => {
          const {
            default_user_playlists = [],
            default_practice_playlists = [],
            global_playlists = [],
            non_default_playlists = [],
            treatment_categories = [],
          } = response.data;
  
          const allPlaylists = [
            ...default_user_playlists.map((item, index) => {
              if (index === 0) {
                setIteamId(item.id);
              }
              return {
                ...item,
                type: "default_user_playlists",
              };
            }),
            ...default_practice_playlists.map((item) => ({
              ...item,
              type: "default_practice_playlists",
            })),
            ...global_playlists.map((item) => ({
              ...item,
              type: "global_playlists",
            })),
            ...non_default_playlists.map((item) => ({
              ...item,
              type: "non_default_playlists",
            })),
          ];
  
          const formattedData = allPlaylists.map((item) => {
            const videos = item.playist_items || [];
            const firstVideoTitle =
              videos.length > 0
                ? videos[0].title || `Category ${item.id}`
                : `Category ${item.id}`;
  
            return {
              id: item.id,
              name: item.title || firstVideoTitle,
              type: item.type,
              level: item.level,
              treatment_category_id: item.treatment_category_id,
              treatment_ids: item.treatment_ids,
              is_default: item.is_default,
              videos: videos.map((video) => ({
                title: video.title,
                url: video.url,
                thumbnail: video.thumbnail,
                description: video.description,
                platform: video.platform,
                duration: video.duration,
              })),
            };
          });
  
          setCategories(formattedData);
  
          if (formattedData.length > 0) {
            setCurrentCategory(formattedData[0]);
            setSelectedCategoryId(formattedData[0].id);
  
            const selectedCategory = treatment_categories.find(
              (cat) => cat.id === formattedData[0].id
            );
  
            setSelectedCategoryName(
              selectedCategory ? selectedCategory.name : "Unknown Category"
            );
  
            if (formattedData[0].videos.length > 0) {
              setCurrentVideo(formattedData[0].videos[0]);
            } else {
              setCurrentVideo(null);
            }
          } else {
            setCurrentCategory(null);
            setCurrentVideo(null);
          }
  
          setSearchLoading(false);
        })
        .catch((error) => {
          console.error("Error fetching the playlist data:", error);
          setSearchLoading(false);
        });
    }, 300),
    [searchParam]
  );

  useEffect(() => {
    if (searchParam.trim() !== "") {
      handleSearch();
    }
  }, [searchParam, handleSearch]);

  useEffect(() => {
    if (selectedTreatment) {
      fetchPlaylistsByTreatment(selectedTreatment);
    }
  }, [selectedTreatment]);

  const handleInputChange = (event) => {
    const { value } = event.target;
    setSearchParam(value);
  };

  const onVideoSelect = (video) => {
    logCurrentVideoTime();
    setCurrentVideo(video);

    logUserAction("Video Selected", "User clicked on a Video", {
      video: video.title,
    });
    resetTimeTracking();
  };

  const logCurrentVideoTime = () => {
    if (startTimeRef.current && currentVideo) {
      const endTime = Date.now();
      const duration = (endTime - startTimeRef.current) / 1000;
      const actualPlaybackTime = playbackTimeRef.current;

      logUserAction("Video Watched", "User stopped watching a video", {
        video: currentVideo.title,
        duration: duration,
        actualPlaybackTime: actualPlaybackTime,
      });
    }
  };

  const logUserAction = (event, description, data) => {
    if (!userid) {
      console.warn("User ID is not defined. Log action is not sent.");
      return;
    }

    axios
      .post(`/log`, {
        type: "info",
        description: description,
        event: event,
        data: data,
        practice_id: practiceId,
        user_id: userid,
      })
      .then((response) => {})
      .catch((error) => {
        console.error("Error logging user action:", error);
      });
  };

  const resetTimeTracking = () => {
    startTimeRef.current = Date.now();
    playbackTimeRef.current = 0;
  };

  const handleVideoStart = () => {
    resetTimeTracking();
  };
  const [seekTime, setSeekTime] = useState(null);

  const handleVideoProgress = (state) => {
    setLastPlayedSeconds(state.playedSeconds);
    playbackTimeRef.current = state.playedSeconds;
  };

  const handleVideoPause = () => {
    setPlayedBeforePause(lastPlayedSeconds);
    handleVideoResume();
    logCurrentVideoTime();
    resetTimeTracking();
  };

  const handleVideoResume = () => {
    const newPlayedSeconds = lastPlayedSeconds;
    const calculatedSeekTime = newPlayedSeconds - (playedBeforePause || 0);
    setSeekTime(calculatedSeekTime);

    // Clear
    setSeekTime(null);
  };

  const handleVideoEnd = () => {
    logCurrentVideoTime();
    resetTimeTracking();
  };

  const handleModalOpen = () => setOpenModalPlaylist(true);
  const handleModalClose = () => setOpenModalPlaylist(false);
  const handleModalOpenPlaylist = () => setOpenModal(true);



  if (loading) {
    return (
      <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh', position: 'relative' }}>
        <Lottie animationData={videoLoadingAnimation} style={{ width: 350, height: 350 }} />
        <img
          src="/logo.png" 
          alt="Overlay image"
          style={{
            position: 'absolute',
            top: '45%',  // Adjusted value to move the image upwards
            left: '50%',
            transform: 'translate(-50%, -50%)',
            width: 200, // adjust as necessary
            height: 90, // adjust as necessary
          }}
        />
      </div>
    );
  }

  const handleTreatmentChange = (event, newValue) => {
    setSelectedTreatmentIds(newValue.map((option) => option.value));
  };


  const handleTreatmentChangeNew = (event, newValue) => {
    setSelectedTreatmentIdsNew(newValue.map((option) => option.value));
  };

  const handleSubmitPlaylistItemSave = async (newVideo) => {
    try {
      const updatedCategory = { ...currentCategory };
      updatedCategory.videos.push(newVideo);

      setCategories((prevCategories) =>
        prevCategories.map((cat) =>
          cat.id === updatedCategory.id ? updatedCategory : cat
        )
      );

      setCurrentCategory(updatedCategory);
      setCurrentVideo(newVideo);
      setOpenModal(false);
    } catch (error) {
      toast.error("Error saving playlist item.");
      console.error("Error saving playlist item:", error);
    }
  };

  const getFilteredPlaylists = () => {
    if (filter === "all") return categories;
    let filtered = [];
    if (filter === "default_user_playlists") {
      filtered = categories.filter(
        (cat) => cat.type === "default_user_playlists"
      );
    } else if (filter === "global_playlists") {
      filtered = categories.filter((cat) => cat.type === "global_playlists");
    } else if (filter === "non_default_playlists") {
      filtered = categories.filter(
        (cat) => cat.type === "non_default_playlists"
      );
    } else if (filter === "treatment_categories") {
      filtered = categories.filter(
        (cat) => cat.type === "treatment_categories"
      );
    } else if (filter === "default_practice_playlists") {
      filtered = categories.filter(
        (cat) => cat.type === "default_practice_playlists"
      );
    }
    return filtered.length > 0 ? filtered : null;
  };
  const filteredPlaylists = getFilteredPlaylists();



  const handleDeletePlaylist = (playlistId) => {
    axios
      .delete(`/playlist/${playlistId}`)
      .then(() => {
        setCategories((prevCategories) =>
          prevCategories.filter((category) => category.id !== playlistId)
        );
        if (currentCategory?.id === playlistId) {
          setCurrentCategory(null);
          setCurrentVideo(null);
        }
        handleData();
        toast.success("Playlist deleted successfully");
      })
      .catch((error) => {
        toast.error("Error deleting playlist.");
        console.error("Error deleting playlist:", error);
      });
  };
  const handleDelete = async (videoToDelete) => {
    try {
      const updatedVideos = currentCategory.videos.filter(video => video !== videoToDelete);
      const payload = updatedVideos.map(({ thumbnail, ...video }) => ({
        ...video,
        type: 'mp4', 
      }));

      const response = await axios.put(`playlist/${itemId}/save_playlist_items`, payload);
    
      if (response.status === 200) {
        const result = await response.data;
        if (result === true) {
          toast.success("Playlist item deleted successfully");
          const updatedCategory = {
            ...currentCategory,
            videos: updatedVideos,
          };
          
          setCategories((prevCategories) => {
            return prevCategories.map((category) =>
              category.id === updatedCategory.id ? updatedCategory : category
            );
          });
  
          setCurrentCategory(updatedCategory);
  
        } else {
          toast.error("Failed to delete playlist item.");
        }
      } else {
        toast.error("Failed to delete playlist item.");
      }
    } catch (error) {
      console.error("Error deleting playlist item:", error);
      toast.error("An error occurred while deleting the playlist item.");
    }
  };
  




 
  //global copy 
  

  return (
    <Box className={styles.app}>
      <h1 className={styles.header}>Video Gallery</h1>

      <Dialog
        open={openModalPlaylist}
        onClose={handleModalClose}
        PaperProps={{
          sx: {
            width: "600px",
            borderRadius: "15px",
            padding: "20px",
          },
        }}
      >
        <DialogTitle>Add New Playlist</DialogTitle>
        <DialogContent>
          <Form
            onSubmit={handleSubmitPlaylistSave}
            render={({ handleSubmit }) => (
              <form onSubmit={handleSubmit}>
                <Box mt={2}>
                  <Field name="playlistName">
                    {({ input }) => (
                      <TextField
                        label="Playlist Name"
                        variant="outlined"
                        fullWidth
                        {...input}
                        autoFocus
                      />
                    )}
                  </Field>
                </Box>
                <Box>
                  <Field name="category_id">
                    {({ input }) => (
                      <TextField
                        select
                        label="Category"
                        variant="outlined"
                        fullWidth
                        {...input}
                        margin="normal"
                      >
                        {playlistCategories?.map((category) => (
                          <MenuItem value={category.id} key={category.id}>
                            {category.category_name}
                          </MenuItem>
                        ))}
                      </TextField>
                    )}
                  </Field>
                </Box>

                <Box mb={2} mt={1}>
                  <Autocomplete
                    name="treatment_idsnew"
                    label="Treatment"
                    multiple
                    required={true}
                    options={treatmentPlans}
                    getOptionLabel={(option) => option.label}
                    getOptionSelected={(option, value) =>
                      option.value === value
                    }
                    onChange={handleTreatmentChangeNew}
                    renderInput={(params) => (
                      <TextField {...params} label="Treatment" />
                    )}
                  />
                </Box>

                <DialogActions>
                  <Button onClick={handleModalClose} color="primary">
                    Cancel
                  </Button>
                  <Button type="submit" color="primary">
                    Add
                  </Button>
                </DialogActions>
              </form>
            )}
          />
        </DialogContent>
      </Dialog>

      <Grid container spacing={2}>
        <Grid item xs={3}>
          <Box className={styles.categoriesBox}>
            {/* Playlist Header */}
            <Box
              display="flex"
              justifyContent="space-between"
              alignItems="center"
              mb={2}
            >
              <h2>Playlist</h2>
              <Button variant="contained" onClick={handleModalOpen}>
                + Add New Playlist
              </Button>
            </Box>

            <Box
              className={styles.searchFilterBox}
              display="flex"
              alignItems="center"
            >
              <TextField
                label="Search"
                variant="filled"
                value={searchParam}
                name="searchParam"
                onChange={handleInputChange}
                fullWidth
              />
              <Box mt={1} />
              <Select
                value={selectedTreatment}
                onChange={handleTreatmentChange}
                displayEmpty
                variant="filled"
                fullWidth
                className={styles.selectBox}
                style={{ marginLeft: "8px" }}
              >
                <MenuItem value="" disabled>
                  Filter by Category
                </MenuItem>
                {treatments.map((treatment) => (
                  <MenuItem key={treatment.value} value={treatment.value}>
                    {treatment.label}
                  </MenuItem>
                ))}
              </Select>
            </Box>

            {/* Filter Chips */}
            <Box mt={2} display="flex" flexWrap="wrap" gap={1}>
              <Chip
                label="All"
                onClick={() => setFilter("all")}
                color={filter === "all" ? "primary" : "default"}
              />
              <Chip
                label="User"
                onClick={() => setFilter("default_user_playlists")}
                color={
                  filter === "default_user_playlists" ? "primary" : "default"
                }
              />
              <Chip
                label="Practice"
                onClick={() => setFilter("default_practice_playlists")}
                color={
                  filter === "default_practice_playlists"
                    ? "primary"
                    : "default"
                }
              />
              <Chip
                label="Global"
                onClick={() => setFilter("global_playlists")}
                color={filter === "global_playlists" ? "primary" : "default"}
              />
              <Chip
                label="Archived"
                onClick={() => setFilter("non_default_playlists")}
                color={
                  filter === "non_default_playlists" ? "primary" : "default"
                }
              />
            </Box>

            {/* Clear Filters */}
            {(searchParam || selectedTreatment) && (
              <Button
                type="button"
                title="Clear Filters"
                onClick={() => {
                  setSearchParam("");
                  setSelectedTreatment("");
                  setFilter("all");
                  window.location.reload();
                }}
                className={styles.clear}
              >
                <Clear /> Clear filters
              </Button>
            )}

        {searchLoading && <CircularProgress />}
            <div>
            <Box mt={1} sx={{ maxHeight: "710px", overflowY: "auto" }}>
              {filteredPlaylists === null ? (
                <p
                  style={{
                    textAlign: "center",
                    padding: "10px",
                    fontSize: "16px",
                  }}
                >
                  No playlist
                </p>
              ) : (
                filteredPlaylists.map((category, index) => (
                  <div
                    key={index}
                    className={styles.categoryItem}
                    onClick={() => onCategorySelect(category)}
                    style={{
                      backgroundColor: category.id === selectedCategoryId ? '#add8e6' : 'transparent', 
                      borderRadius: category.id === selectedCategoryId ? '8px' : '0px', 
                      padding: '8px',
                      transition: 'background-color 0.3s ease, border-radius 0.3s ease', // Smooth transition
                    }}
                  >
                    <div className={styles.categoryContainer}>
                      {category.type === "global_playlists" && (
                        <img src="/logo_mono.png" alt="small icon" className={styles.smallImage} />
                      )}
                      {category.type === "default_user_playlists" && <Person2Icon />}
                      {category.type === "default_practice_playlists" && <Groups2Icon />}
                      {category.type === "non_default_playlists" && (
                        <DeleteForeverIcon sx={{ color: "red" }} />
                      )}

                      <span className={styles.categoryName}>{category.name}</span>
                    </div>

                    {category.type !== "global_playlists" && category.type !== "non_default_playlists" && (
                      <IconButton
                        aria-label="delete"
                        onClick={(e) => {
                          e.preventDefault();
                          e.stopPropagation();
                          confirmAlert({
                            title: "Warning",
                            message: "Are you sure you want to delete this playlist?",
                            buttons: [
                              {
                                label: "Yes",
                                onClick: async () => handleDeletePlaylist(category.id),
                              },
                              { label: "Cancel", onClick: () => {} },
                            ],
                          });
                        }}
                        className={styles.deleteIcon}
                      >
                        <Delete />
                      </IconButton>
                    )}
                  </div>
                ))
              )}
            </Box>
            </div>
          </Box>
        </Grid>

        <Grid item xs={9}>
          <Box className={styles.box}>
            <h1>{currentCategory?.name}</h1>
            <div className={styles.playerContainer}>
              {currentVideo || categories.length === 0 ? (
                <ReactPlayer
                  url={currentVideo?.url}
                  controls
                  width="70%"
                  height="520px"
                  onStart={() => {
                    handleVideoStart();
                  }}
                  onProgress={(state) => {
                    handleVideoProgress(state);
                  }}
                  onPause={() => {
                    handleVideoPause();
                  }}
                  onEnded={() => {
                    handleVideoEnd();
                  }}
                  onPlay={() => {
                    handleVideoResume();
                  }}
                  onSeek={() => {
                    handleVideoSeek();
                  }}
                />
              ) : (
                <div>No videos available</div>
              )}

              <div className={styles.playlist}>
                <h2>Videos </h2>

              
                {categories.length === 0 ? (<Skeleton variant="rectangular" height={118} />
                  ) : (
                    currentCategory?.videos.map((video, index) => (
                      <div
                        key={index}
                        className={`${styles.playlistItem} ${video === currentVideo ? styles.active : ""}`}
                        onClick={() => onVideoSelect(video)}
                        style={{ position: 'relative', paddingRight: '30px' }} 
                      >
                        <img
                          src={video.thumbnail}
                          alt={video.title}
                          className={styles.thumbnail}
                        />
                        
                        <div style={{ paddingRight: '30px' }}>{video.title}</div> 
                        
                        {/* Delete Icon */}
                        <DeleteIcon
                          onClick={(e) => {
                            e.preventDefault();
                            e.stopPropagation();
                            confirmAlert({
                              title: "Warning",
                              message: "Are you sure you want to delete this playlist item?",
                              buttons: [
                                {
                                  label: "Yes",
                                  onClick: async () => {
                                  
                                    await handleDelete(video);
                                  },
                                },
                                { label: "Cancel", onClick: () => {} },
                              ],
                            });
                          }}
                          style={{
                            position: 'absolute',
                            top: '5px',
                            right: '5px',
                            cursor: 'pointer',
                            color: 'red', 
                          }}
                        />
                      </div>
                    ))
                  )
                }
                 {
                    level !== 'global' && !isArchived && (
                      <Button
                        variant="contained"
                        style={{
                          backgroundColor: "orange",
                          color: "white",
                          position: "absolute",
                        }}
                        onClick={handleModalOpenPlaylist}
                      >
                        Add New Playlist Item
                      </Button>
                    )
                  }
                                  
           
                {currentCategory?.videos[0]?.platform === "s3" && <p></p>}

                <PlaylistModal
                  open={openModal}
                  onClose={() => setOpenModal(false)}
                  onSubmit={handleSubmitPlaylistItemSave}
                  playlistId={selectedCategoryId}
                  currentCategory={currentCategory}
                />
              </div>
            </div>

            {/* forem */}
            {(currentVideo || categories.length === 0) && currentCategory && (
              <Box mb={3} mt={4} sx={{ width: "67.7%" }}>
                <Form onSubmit={handleSubmitPlaylistUpdate}
                  render={({ handleSubmit }) => (
                    <form onSubmit={handleSubmit}>
                      <Box mt={2}>
                        <Field name="playlistName" initialValue={currentCategory?.name || ""}>
                          {({ input }) => (
                            <TextField
                              label="Playlist Name"
                              variant="outlined"
                              fullWidth
                              {...input}
                              autoFocus
                            />
                          )}
                        </Field>
                      </Box>
                      
                      <Box>
                        <Field name="category_id" initialValue={currentCategory?.treatment_category_id || ""}>
                          {({ input }) => (
                            <TextField
                              select
                              label="Category"
                              variant="outlined"
                              fullWidth
                              {...input}
                              margin="normal"
                            >
                              {playlistCategories?.map((category) => (
                                <MenuItem value={category.id} key={category.id}>
                                  {category.category_name}
                                </MenuItem>
                              ))}
                            </TextField>
                          )}
                        </Field>
                      </Box>

                      <Box mb={2} mt={1}>
                      <Autocomplete
                        name="treatment_ids"
                        label="Treatment"
                        multiple
                        required
                        options={treatmentPlans}
                        getOptionLabel={(option) => option.label}
                        isOptionEqualToValue={(option, value) => option.value === value}
                        value={treatmentPlans.filter((option) => selectedTreatmentIds.includes(option.value))}
                        onChange={handleTreatmentChange}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            label="Treatment"
                            error={selectedTreatmentIds.length === 0} // If nothing is selected, show the error
                            helperText={selectedTreatmentIds.length === 0 ? 'At least select one treatment' : ''}
                          />
                        )}
                      />
                    </Box>


                      <Box display="flex" alignItems="center" gap={2} mb={1} mt={2}>
                        <FormControl component="fieldset">
                          <FormLabel component="legend">Select Option</FormLabel>
                          {currentCategory?.level && (
                            <Field name="options" initialValue={currentCategory.level}>
                              {({ input }) => (
                                <RadioGroup
                                  row
                                  {...input}
                                  value={input.value}
                                  onChange={input.onChange}
                                >
                                  <FormControlLabel
                                    value="user"
                                    control={<Radio />}
                                    label="User"
                                  />
                                  <FormControlLabel
                                    value="practice"
                                    control={<Radio />}
                                    label="Practice"
                                  />
                                </RadioGroup>
                              )}
                            </Field>
                          )}
                        </FormControl>
                      </Box>

                      <Button type="submit" variant="contained" sx={{ width: "100%" }}>
                        Save
                      </Button>
                    </form>
                  )}
                />
              </Box>
            )}

          </Box>
        </Grid>
      </Grid>
    </Box>
  );
}
